import { PredictorPrimeService } from '@wix/ambassador-predictor-prime-service/http';
import { AmbassadorBaseUrl } from '../../constants/ambassador';
import { HasImageTextByDataScienceParams } from './hasImageTextByDataScience.types';

export const hasImageTextByDataScience = async ({
  base64Image,
  imageId,
  imageUrl,
  metaSiteId,
  siteId,
}: HasImageTextByDataScienceParams): Promise<boolean> => {
  const service = PredictorPrimeService(
    AmbassadorBaseUrl.PredictorPrimeService,
  ).Predictor()();

  const { output } = await service.invokeByModel({
    features: {
      values: {
        encoded_image: {
          strVal: base64Image,
        },
        image_id: {
          strVal: imageId,
        },
        image_url: {
          strVal: imageUrl,
        },
        meta_site_id: {
          strVal: metaSiteId,
        },
        site_id: {
          strVal: siteId,
        },
      },
    },
    modelId: 'ds-text-detection-0-1',
    triggeredBy: 'testing',
  });

  if (output?.values?.result.strVal) {
    return JSON.parse(output.values.result.strVal).has_text;
  }

  return false;
};
